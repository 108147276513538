import React from 'react';
import { View, StyleSheet } from 'react-native';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Auth from '../../components/auth-wrapper';
import Grapph from '../../components/grapph'
import { useProjectQuery } from '../../apollo'
import Phone from '../../components/phone'
import { ErrorView } from '../../components/ui'

const styles = StyleSheet.create({
  previewContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row'
  }
})

type RouteParams = {
  id: string;
}

export interface ProjectIdProps extends RouteComponentProps<RouteParams> {}

const ProjectsId: React.FC<ProjectIdProps> = (props) => {
  const { match } = props
  const { error } = useProjectQuery({ variables: { id: match.params.id } })

  if (error) {
    return <ErrorView error={error} />
  }

  return (
    <Auth>
      <View style={styles.previewContainer}>
        <Phone>
          <Grapph {...props}/>
        </Phone>
      </View>
    </Auth>
  );
};

export default withRouter<any>(ProjectsId);
