import React from 'react';
import Svg, { Path, Text } from 'react-native-svg';

interface Props {
  color: string;
}

const Notch: React.FC<Props> = props => (
  <Svg
    width={375}
    height={44}
    fill={props.color ? props.color : 'rgb(0,0,0)'}
    {...props}>
    <Path d="M339.418 17.061c-.933 0-1.253.066-1.582.253a1.463 1.463 0 00-.598.632c-.176.349-.238.689-.238 1.676v4.761c0 .988.062 1.327.238 1.676.139.275.337.485.598.633.329.186.649.252 1.582.252h15.163c.933 0 1.254-.066 1.583-.252.26-.148.459-.358.598-.633.176-.349.238-.688.238-1.676v-4.76c0-.988-.062-1.328-.238-1.677a1.469 1.469 0 00-.598-.633c-.329-.186-.65-.252-1.583-.252h-15.163zm0-1.058h15.163c1.189 0 1.62.13 2.055.377.434.246.775.607 1.008 1.067.232.46.356.917.356 2.175v4.761c0 1.259-.124 1.715-.356 2.175-.233.46-.574.821-1.008 1.067-.435.247-.866.378-2.055.378h-15.163c-1.188 0-1.619-.131-2.054-.378a2.494 2.494 0 01-1.008-1.067c-.232-.46-.356-.916-.356-2.175v-4.76c0-1.26.124-1.716.356-2.176.232-.46.573-.821 1.008-1.067.435-.246.866-.377 2.054-.377zM359 19.885c.805.359 1.328 1.193 1.328 2.118 0 .924-.523 1.759-1.328 2.117v-4.235z" />
    <Path d="M339.709 18.003h14.582c.594 0 .81.067 1.027.194.217.127.388.313.504.55.116.237.178.472.178 1.12v4.271c0 .648-.062.883-.178 1.12a1.273 1.273 0 01-.504.55c-.217.127-.433.195-1.027.195h-14.582c-.594 0-.809-.068-1.027-.195a1.273 1.273 0 01-.504-.55c-.116-.236-.178-.472-.178-1.12v-4.27c0-.65.062-.884.178-1.121.116-.237.287-.423.504-.55.218-.127.433-.194 1.027-.194zM323.33 18.277a8.64 8.64 0 015.953 2.379.308.308 0 00.433-.004l1.156-1.166a.322.322 0 00-.002-.456 10.897 10.897 0 00-15.08 0 .322.322 0 00-.003.456l1.156 1.166c.119.12.313.122.433.004a8.644 8.644 0 015.954-2.379zm0 3.796c1.218 0 2.392.452 3.294 1.27a.31.31 0 00.433-.006l1.155-1.167a.321.321 0 00-.005-.459 7.159 7.159 0 00-9.752 0 .323.323 0 00-.004.46l1.154 1.166a.31.31 0 00.433.006 4.907 4.907 0 013.292-1.27zm2.219 2.784a.312.312 0 00-.009-.457 3.423 3.423 0 00-4.42 0 .315.315 0 00-.009.457l1.997 2.016a.314.314 0 00.444 0l1.997-2.016zM294.666 23.003h1a1 1 0 011 1v2a1 1 0 01-1 1h-1a1 1 0 01-.999-1v-2a1 1 0 01.999-1zm4.667-2h1a1 1 0 011 1v4a1 1 0 01-1 1h-1a1 1 0 01-1-1v-4a1 1 0 011-1zM304 18.669h1a1 1 0 011 1v6.334a1 1 0 01-1 1h-1a1 1 0 01-1-1v-6.334a1 1 0 011-1zm5.666-2.333a1 1 0 011 1v8.667a1 1 0 01-1 1h-1a1 1 0 01-1-1v-8.667a1 1 0 011-1h1z" />
    <Path clipRule="evenodd" d="M65.455 26.5L70 16l-10.5 4.545h5.955V26.5z" />
    <Text fontSize={14} fontWeight="bold" x={28} y={26}>
      {'7:29'}
    </Text>
    <Path
      fill="#000"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292 5c0 .74.123 1.479.123 2.22V8c0 12.15-9.85 22-22 22H104.877c-12.15 0-22-9.85-22-22v-.78c0-.741.123-1.48.123-2.22a5 5 0 00-5-5h219a5 5 0 00-5 5z"
    />
  </Svg>
);

const Footer: React.FC<Props> = props => (
  <Svg
    width={375}
    height={34}
    fill={props.color ? props.color : 'rgb(0,0,0)'}
    {...props}>
    <Path d="M120 23.5a2.5 2.5 0 012.5-2.5h130a2.5 2.5 0 110 5h-130a2.5 2.5 0 01-2.5-2.5z" />
  </Svg>
);

export { Notch, Footer };
