import React from 'react';
import {
  TextInput as RnTextInput,
  View,
  StyleSheet,
  TextInputProps,
} from 'react-native';
import { colors } from '../../defaultStyles';
import Label from './label';

const styles = StyleSheet.create({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  input: {
    height: 45,
    width: '100%',
    backgroundColor: colors.darkBackground,
    borderRadius: 5,
    boxShadow: 'inset 0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
    color: colors.midGrey,
    fontSize: 16,
    paddingLeft: 16,
  },
});

interface Props extends TextInputProps {
  value?: string;
  label?: string;
}

const TextInput: React.FC<Props> = ({
  label,
  value,
  onChangeText,
  onChange,
  ...rest
}) => {
  // TODO: This should be configured from the theme
  const labelColor = 'primary';
  return (
    <View style={styles.inputContainer}>
      {label && <Label label={label} color={labelColor} />}
      <RnTextInput
        style={styles.input}
        value={value && value}
        onChange={onChange && onChange}
        onChangeText={onChangeText && onChangeText}
        {...rest}
      />
    </View>
  );
};

export default TextInput;
