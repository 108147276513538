import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import Projects from '../pages/projects/index';
import ProjectsId from '../pages/projects/_id';
import Index from '../pages/index';
import Settings from '../pages/settings';
import Login from '../pages/login';
import Register from '../pages/register';
import Navbar from '../components/nav-bar';
import client from '../apollo/client';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/projects" component={Projects} />
          <Route path="/projects/:id" component={(props: any) => <ProjectsId {...props}/>} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default App;
