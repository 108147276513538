import React, { useEffect } from 'react';
import { withRouter, RouterProps, RouteComponentProps } from 'react-router'

interface IndexProps {
  redirect?: string
}

const Index: React.FC<IndexProps & RouterProps & RouteComponentProps> = ({ history, match, redirect }) => {
  useEffect(() => {
    if (redirect) {
      history.replace(`${match.url}${redirect}`)
    }
  }, [history, match, redirect])
  return <></>
}

export default withRouter<any>(Index)