import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { colors, Colors } from '../../defaultStyles';

const useStyles = (
  color?: Colors,
  h1?: boolean,
  h2?: boolean,
  h3?: boolean,
  h4?: boolean,
  styles?: any,
) => {
  let size = 16;
  if (h1) {
    size = 40;
  } else if (h2) {
    size = 34;
  } else if (h3) {
    size = 28;
  } else if (h4) {
    size = 22;
  }
  let fontColor = color ? colors[color] : colors.primary;
  return StyleSheet.create({
    title: {
      fontSize: size,
      color: fontColor,
      ...styles,
    },
  });
};

interface Props {
  color?: Colors;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  styles?: object | any;
}

const Title: React.FC<Props> = ({
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  color,
  styles,
  children,
}) => {
  const style = useStyles(color, h1, h2, h3, h4, styles);
  return <Text style={style.title}>{children}</Text>;
};

export default Title;
