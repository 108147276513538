import React from 'react';
import {
  View,
  StyleSheet,
  ButtonProps,
  Button as RnButton,
} from 'react-native';
import { colors, Colors } from '../../defaultStyles';

const styles = StyleSheet.create({
  loginButton: {
    minWidth: 150,
  },
});

interface Props extends ButtonProps {
  color?: Colors;
}

const Button: React.FC<Props> = ({ color, title, onPress, ...rest }) => {
  let btnColor = color ? colors[color] : colors.primary;
  return (
    <View style={styles.loginButton}>
      <RnButton
        color={btnColor}
        title={title && title}
        onPress={onPress}
        {...rest}
      />
    </View>
  );
};

export default Button;
