import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { colors } from '../defaultStyles';
import Auth from './auth-wrapper';

const styles = StyleSheet.create({
  section: {
    paddingTop: 60,
    paddingBottom: 60,
    backgroundColor: colors.darkBackground,
    height: 'calc(100vh - 75px)',
  },
  container: {
    margin: 'auto',
    marginTop: 0,
    marginBottom: 0,
    width: '90%',
    maxWidth: 1180,
  },
  pageTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    color: colors.primary,
    fontSize: 48,
    marginBottom: '2rem',
  },
});

interface Props {
  title: string;
  withAuth?: boolean;
}

const Layout: React.FC<Props> = ({ title, children, withAuth }) => {
  if (withAuth) {
    return (
      <Auth>
        <View style={styles.section}>
          <View style={styles.container}>
            <Text style={styles.pageTitle}>{title}</Text>
            <View>{children}</View>
          </View>
        </View>
      </Auth>
    );
  }
  return (
    <View style={styles.section}>
      <View style={styles.container}>
        <Text style={styles.pageTitle}>{title}</Text>
        <View>{children}</View>
      </View>
    </View>
  );
};

export default Layout;
