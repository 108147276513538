import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { colors, Colors } from '../../defaultStyles';

interface LabelTypes {
  color: Colors;
  label: string;
}

const useStyles = (colorProp: Colors) => {
  let txtColor = colorProp ? colors[colorProp] : colors.primary;
  return StyleSheet.create({
    label: {
      marginBottom: 14,
      fontSize: 16,
      color: txtColor,
    },
  });
};

const Label: React.FC<LabelTypes> = ({ label, color }) => {
  let styles = useStyles(color);
  return <Text style={styles.label}>{label}</Text>;
};

export default Label;
