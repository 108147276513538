import React from 'react';
import { createPortal } from 'react-dom';
import {
  View,
  StyleSheet,
  NativeSyntheticEvent,
  NativeTouchEvent,
  ModalProps,
} from 'react-native';
// @ts-ignore
import RnModal from 'modal-enhanced-react-native-web';

export type ButtonPress = (e: NativeSyntheticEvent<NativeTouchEvent>) => void;

interface Props extends ModalProps {
  visible?: boolean;
  closeButton?: boolean;
  setVisible?: (visible: boolean) => void;
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  box: {
    padding: '15px',
    backgroundColor: '#fff',
    width: 500,
    maxWidth: '100%',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

const Modal: React.FC<Props> = ({
  children,
  visible = false,
  onRequestClose,
  setVisible,
}) => {
  const closeModal: ButtonPress = e => {
    if (setVisible) {
      setVisible(false);
    }
    if (onRequestClose) {
      onRequestClose();
    }
  };
  return createPortal(
    <RnModal
      animationIn="zoomIn"
      animationOut="zoomOut"
      isVisible={visible}
      onBackdropPress={closeModal}>
      <View style={styles.container}>
        <View style={styles.box}>
          {children}
        </View>
      </View>
    </RnModal>,
    document.body,
  );
};

export default Modal;
