export enum ColorsEnum {
  darkGrey = 'darkGrey',
  midGrey = 'midGrey',
  lightGrey = 'lightGrey',
  darkBackground = 'darkBackground',
  white = 'white',
  Primary = 'primary',
  heroBackground = 'heroBackground',
}
export type Colors =
  | 'darkGrey'
  | 'midGrey'
  | 'lightGrey'
  | 'darkBackground'
  | 'white'
  | 'primary'
  | 'heroBackground';

type ColorsType = { [key in Colors]?: string };

export const colors: ColorsType = {
  darkGrey: '#333333',
  midGrey: '#5E5E5E',
  lightGrey: '#D9D9D9',
  darkBackground: 'rgb(250, 251, 252)',
  white: '#FFFFFF',
  primary: '#A277C8',
  heroBackground: 'linear-gradient(45deg, #D69356 0%, #8E6CF0 100%)',
};

interface PaperType {
  boxShadow: string;
  borderRadius: number;
}

export const paper: PaperType = {
  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.12)',
  borderRadius: 8,
};
