import { AppRegistry, Platform } from 'react-native';
import App from './App/index';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));
AppRegistry.registerComponent('builder', () => App);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('builder', {
    rootTag: document.getElementById('root'),
  });
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

export default App;
