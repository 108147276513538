import React from 'react'
import { View, StyleSheet } from 'react-native'
import NavItem, { NavItemProps } from './nav-item'

interface StylesProps {
  app?: boolean
  height?: number
}

const useStyles = ({ app, height }: StylesProps) => StyleSheet.create({
  navBarContainer: {
    height: height || 100,
    width: '100%',
    backgroundColor: 'white',
    shadowColor: 'black',
    
    position: app ? 'absolute' : 'relative',
    bottom: app ? 0 : undefined,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
  }
})

interface NavItem {
  name: string
  icon?: string
  to: string
}

interface Props extends StylesProps {
  navItems: NavItemProps[]
}

const NavBar: React.FC<Props> = ({ app, height = 50, navItems }) => {
  const styles = useStyles({ app, height })
  return (
    <View style={styles.navBarContainer}>
      {navItems.map(navItem => <NavItem key={navItem.name} {...navItem}/>)}
    </View>
  )
}

export default NavBar