import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../helpers/use-auth';

const Auth = ({ children }: any) => {
  const { loading, loggedIn } = useAuth();
  if (!loading && !loggedIn) {
    return <Redirect to="/login" />;
  }
  return children;
};

export default Auth;
