import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { View, ActivityIndicator, ScrollView, SafeAreaView } from 'react-native';
import { useProjectGrapphIntrospectionQuery } from '../../apollo'
import { ErrorView } from '../ui'
import NavBar from './nav-bar'
import Index from './pages'
import Field from './pages/_field'
import idx from 'idx'

type GrapphRouteParams = {
  page?: string
  id?: string
}

export type GrapphRouteComponentProps = RouteComponentProps<GrapphRouteParams>

interface GrapphProps {}

const Grapph: React.FC<GrapphProps & GrapphRouteComponentProps> = ({ match }) => {
  const projectId = match.params.id!
  const { data, loading, error } = useProjectGrapphIntrospectionQuery({ variables: { projectId } })
  const navBarHeight = 75
  const types = idx(data, _ => _.project.grapphSchema.types)
  if (loading || !types) {
    return <View><ActivityIndicator size="large"/></View>
  }
  if (error) {
    console.error(error)
    return <ErrorView error={error}/>
  }
  const rootQuery = types.find(type => type!.name! === 'Query')
  const navItems = rootQuery!.fields!
    .filter(field => field!.name! !== 'query' && (idx(field, _ => _.type.kind) === 'LIST'))
    .map(field => ({ name: field!.name!, to: `/${field!.name!}` }))
  return (
    <SafeAreaView style={{ flex: 1, paddingBottom: navBarHeight }}>
      <ScrollView>
        <Switch>
          <Route exact path={`${match.path}`} component={() => <Index redirect={navItems[0].to}/>} />
          <Route exact path={`${match.path}/:field`} component={(props: RouteComponentProps) => <Field projectId={projectId} {...props}/>} />
        </Switch>
      </ScrollView>
      {navItems && <NavBar app height={navBarHeight} navItems={navItems} {...match}/>}
    </SafeAreaView>
  );
};

export default Grapph;
