import ApolloClient from 'apollo-boost';
import { Platform } from 'react-native';

const isWeb = Platform.OS === 'web';

export const createClient = (projectId: string) => {
  const uri = isWeb
    ? `${window.location.origin}/proxy/${projectId}`
    : process.env.NODE_ENV === 'production'
      ? `https://grapph.now.sh/proxy${projectId}`
      : `http://localhost:3000/proxy/${projectId}`;
  return new ApolloClient({ uri })
}
