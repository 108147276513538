import React, { useState } from 'react';
import { View, Text, AsyncStorage } from 'react-native';
import { withRouter, Link } from 'react-router-dom';
import GrapphLogo from '../icons/grapph-logo';
import { useApolloClient } from '@apollo/react-hooks';
import { useLogInMutation, useSignUpMutation } from '../../apollo';
import { Button, TextInput, Title } from '../ui';
import styles from './styles';
import { State, LoginProps, FormTypeOptions } from './types';

const formTypeOps: FormTypeOptions = {
  logIn: {
    hook: useLogInMutation,
    title: 'Login',
    btnTitle: 'Login',
    redirect: {
      text: "Don't have an account?",
      route: '/register',
      btnText: 'Register now',
    },
  },
  signUp: {
    hook: useSignUpMutation,
    title: 'Sign up',
    btnTitle: 'Create Account',
    redirect: {
      text: 'Already have an account?',
      route: '/login',
      btnText: 'Login now',
    },
  },
};

const Login: React.FC<LoginProps> = ({ history, formType }) => {
  const client = useApolloClient();
  const [state, setState] = useState<State>({
    email: '',
    password: '',
    msg: '',
    error: false,
    remember: false,
  });
  const { hook, title, btnTitle, redirect } = formTypeOps[formType];
  const [login, { loading, error }] = hook({
    onCompleted(data: any) {
      const { jwt, user } = formType === 'logIn' ? data.logIn : data.signUp;
      AsyncStorage.setItem('token', jwt);
      client.writeData({ data: { currentUser: user } });
      history.push('/');
    },
  });

  const handleChange = (value: string | boolean, name: string) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { email, password } = state;
    setState(prevState => ({
      ...prevState,
      error: false,
      msg: '',
    }));

    if (!email || !password) {
      setState(prevState => ({
        ...prevState,
        msg: 'Email and Password are required',
        error: true,
      }));
      return;
    }

    await login({
      variables: {
        email,
        password,
      },
    });
  };
  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <GrapphLogo height="115" />
      </View>
      <View style={styles.box}>
        <Title
          h3
          color="primary"
          styles={{ textAlign: 'left', marginBottom: 32 }}>
          {title}
        </Title>
        <View>
          <TextInput
            autoFocus={true}
            keyboardType="email-address"
            value={state.email}
            label="Email"
            onChangeText={text => handleChange(text, 'email')}
          />
          <TextInput
            secureTextEntry={true}
            value={state.password}
            label="Password"
            onChangeText={text => handleChange(text, 'password')}
          />
          <View style={styles.loginContainer}>
            <View style={styles.loginButton}>
              <Button
                color="primary"
                title={btnTitle}
                onPress={handleSubmit}
                disabled={loading}
              />
            </View>
          </View>
          {state.error && <Text>{state.msg}</Text>}
          {error && <Text>There was an error logging in</Text>}
        </View>
      </View>

      <View style={styles.linkContainer}>
        <Text style={styles.text}>{redirect.text}</Text>
        <Link to={redirect.route}>
          <Text style={styles.link}>{redirect.btnText}</Text>
        </Link>
      </View>
    </View>
  );
};

export default withRouter<any>(Login);
