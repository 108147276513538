import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator
} from 'react-native';
import Layout from '../components/layout';
import { useCurrentUserQuery } from '../apollo';
import ProjectLinks from '../components/project-links'

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    paddingTop: 60,
    paddingBottom: 60,
    maxWidth: 990,
    width: '90%',
    height: '100vh',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
});

const Index = () => {
  const { data: currentUser, loading, error } = useCurrentUserQuery()
  if (loading) {
    return <View><ActivityIndicator size="large"/></View>;
  }
  if (error) {
    return <Text>{error.message}</Text>;
  }
  return (
    <Layout title="Dashboard" withAuth>
      <View style={styles.row}>
        {currentUser && <ProjectLinks/>}
      </View>
    </Layout>
  );
};

export default Index;
