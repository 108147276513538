import React from 'react'
import { colors, paper } from '../../defaultStyles';
import { StyleSheet, Text } from 'react-native'

const tileStyles = StyleSheet.create({
  text: {
    width: 200,
    height: 200,
    marginRight: '2rem',
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...paper,
  }
});


const Tile: React.FC<{}> = ({ children }) => {
  return (
    <Text style={tileStyles.text}>{children}</Text>
  );
};

export default Tile