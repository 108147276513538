import React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { StyleSheet, Text, View, AsyncStorage } from 'react-native';
import { colors } from '../defaultStyles';
import { useApolloClient } from '@apollo/react-hooks';
import useAuth from '../helpers/use-auth';
import NavLink from './nav-link';
import GrapphLogo from './icons/grapph-logo';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    maxWidth: '100vw',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    height: 75,
  },
  navMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: 'auto',
    width: '90%',
  },
  navLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  navBtn: {
    backgroundColor: 'transparent',
    color: colors.primary,
    fontSize: 16,
    paddingLeft: 8,
    paddingRight: 8,
    cursor: 'pointer',
  },
  signUp: {
    backgroundColor: colors.primary,
    color: '',
  },
  logoContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoText: {
    marginLeft: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
  },
});

interface Props extends RouteComponentProps {}

const Navbar: React.FC<Props> = ({ history }) => {
  const client = useApolloClient();
  const { loading, loggedIn } = useAuth();

  function logout() {
    client.writeData({ data: { currentUser: null } });
    AsyncStorage.removeItem('token');
    history.push('/login');
  }

  return (
    <View style={styles.wrapper}>
      <View style={styles.nav}>
        <View style={styles.navMenu}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <View style={styles.logoContainer}>
              <GrapphLogo height="42" />
              <Text style={styles.logoText}>Grapph</Text>
            </View>
          </Link>
          {!loading && (
            <View style={styles.navLinks}>
              {loggedIn ? (
                <>
                  <NavLink to="/">Dashboard</NavLink>
                  <NavLink to="/settings">Settings</NavLink>
                  <Text onPress={() => logout()} style={styles.navBtn}>
                    Logout
                  </Text>
                </>
              ) : (
                <>
                  <NavLink to="/login">Login</NavLink>
                  <NavLink to="/register">Sign up</NavLink>
                </>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default withRouter(Navbar);
