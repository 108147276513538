import React, { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';
import { Notch, Footer } from './icons';
import { colors } from '../../defaultStyles'

interface Props {
  children: ReactNode;
}

const styles = StyleSheet.create({
  phone: {
    backgroundColor: colors.darkBackground,
    borderRadius: 40,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 36px 42px',
    width: 375,
    height: 812,
    overflow: 'hidden',
  },
  footer: {
    position: 'absolute',
    bottom: 0
  },
  mainContent: {
    // height: '100%',
    // overflow: 'hidden'
  },
});

const Phone: React.FC<Props> = ({ children }) => {
  return (
    <View style={styles.phone}>
      <View>
        <Notch color={'#000'} />
      </View>
      {children}
      {/* <ScrollView style={styles.mainContent}>{children}</ScrollView> */}
      <View style={[styles.footer]} pointerEvents="none">
        <Footer color={'#000'} />
      </View>
    </View>
  );
};

export default Phone;
