import React from 'react'
import { View, Text } from 'react-native'
import { ApolloError } from 'apollo-boost'

interface ErrorProps {
  error: ApolloError
}

const ErrorView: React.FC<ErrorProps> = ({ error }) => {

  return (
    <View>
      <Text style={{ color: 'red' }}>ERROR</Text>
      <Text>Name: {error.name}</Text>
      <Text>Message: {error.message}</Text>
      <Text>Stack: {error.stack}</Text>
    </View>
  )
}

export default ErrorView
