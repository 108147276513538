import React from 'react';
import { View, StyleSheet } from 'react-native';
import Auth from '../../components/auth-wrapper';

const styles = StyleSheet.create({
  phoneContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
  },
  box: {
    padding: 10,
    flex: 1,
    width: '50%',
  },
});

const Projects: React.FC<{}> = () => {
  return (
    <Auth>
      <View style={styles.container}>
        {/* <View style={styles.box}>
          <EndpointInput />
        </View> */}
        <View style={[styles.phoneContainer]}>
          {/* <Phone>
            <Grapph data={data} />
          </Phone> */}
        </View>
      </View>
    </Auth>
  );
};

export default Projects;
