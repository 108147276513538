import { StyleSheet } from 'react-native';
import { colors, paper } from '../../defaultStyles';

export default StyleSheet.create({
  container: {
    height: 'calc(100vh - 75px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 64,
    backgroundColor: colors.white,
  },
  logoContainer: {
    marginBottom: 32,
  },
  box: {
    width: '90%',
    maxWidth: 450,
    margin: 'auto',
    marginTop: 0,
    marginBottom: 24,
    padding: 40,
    ...paper,
  },
  rememberContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  rememberMe: {
    marginLeft: 16,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 24,
  },
  loginButton: {
    minWidth: 150,
  },
  linkContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    color: colors.midGrey,
    marginRight: 5,
  },
  link: {
    fontSize: 14,
    color: colors.primary,
    cursor: 'pointer',
  },
});
