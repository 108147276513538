import ApolloClient from 'apollo-boost';
import { Platform } from 'react-native';
import { AsyncStorage } from 'react-native';

const isWeb = Platform.OS === 'web';

export const uri = isWeb
  ? `${window.location.origin}/api`
  : process.env.NODE_ENV === 'production'
  ? 'https://grapph.now.sh/api'
  : 'http://localhost:3000/api';

const client = new ApolloClient({
  uri, 
  request: async operation => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  },
});

export default client;
