import React from 'react';
import { View, Text, ActivityIndicator } from 'react-native'
import { RouteComponentProps } from 'react-router';
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { ErrorView } from '../../../components/ui';
import { useProjectGrapphIntrospectionQuery } from '../../../apollo'
import fieldToQuery from '../scripts/field-to-query'
import { createClient } from '../apollo'

interface RouteParams {
  field?: string
}

interface TypeProps {
  projectId: string
  typeName?: string
}
// this is weird af, not sure of a good way to do this
let grapphClient: any

const Type: React.FC<TypeProps & RouteComponentProps<RouteParams>> = ({ projectId, match }) => {
  if (!grapphClient) {
    grapphClient = createClient(projectId)
  }
  const typeName = match.params.field!
  const { data, loading, error } = useProjectGrapphIntrospectionQuery({ variables: { projectId } })
  const types = data!.project!.grapphSchema!.types!
  const rootQuery = types!.find(type => type!.name! === 'Query')
  const queryField = rootQuery!.fields!.find(field => field!.name! === typeName)!
  const gqlString = fieldToQuery(queryField, types)
  const { data: remoteData, loading: remoteLoading } = useQuery<{ [key: string]: any[] }>(gql`${gqlString}`, { client: grapphClient })
  if (loading || remoteLoading || !remoteData || !data ) {
    return <ActivityIndicator size="large"/>
  }
  if (error) {
    return <ErrorView error={error}/>
  }
  return (
    <View>
      <Text>Name: {queryField.name!}</Text>
      <br/>
      <Text>URL: {data!.project!.graphQLUrl!}</Text>
      <br/>
      <Text>GraphQL QUERY: {gqlString}</Text>
      <br/>
      <Text>Result: {JSON.stringify(remoteData[queryField.name!])}</Text>
    </View>
  )
}

export default Type