import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { GrapphFieldValueFragment, GrapphField, GrapphType, GrapphFullTypeFragment, Maybe } from '../../../apollo'
import idx from 'idx';
import defaultVariables from './default-variables'

// There are a number of ways we can do this...
// https://github.com/atulmy/gql-query-builder
// https://github.com/dupski/json-to-graphql-query
// https://github.com/codemeasandwich/graphql-query-builder (or the wix fork https://github.com/wix-playground/graphql-query-builder)

const primitiveTypes = new Set(['Int', 'String', 'ID', 'Float', 'Boolean'])
const isPrimitiveField = (field: Maybe<GrapphField>) => field && field.type && (primitiveTypes.has(field.type.name!) || (field.type.ofType && primitiveTypes.has(field.type.ofType.name!)))

const nonNullType = (type: GrapphType) => idx(type, _ => _.name) === 'NON_NULL' ? type.ofType : type

const createQueryFields: any = (field: GrapphFieldValueFragment, types: GrapphType[], depth: number = 1, currentDepth: number = 0): any => {
  const __args = field.args && field.args.reduce((args, arg) => {
    // TODO: settings - make this number from settings. Prompt user that this needs a value. if it's a number, offer pagination some how.
    const currentType = nonNullType(arg!.type!)
    const newArgVal = idx(currentType, _ => _.name) === 'Int' ? 50 : null;
    const typeName = idx(arg, _ => _.type.name)
    let newArgs = { ...args }
    if (newArgVal && typeName) {
      newArgs = { ...newArgs, [typeName]: newArgVal  }
    }
    return newArgs
  }, {})
  const ofTypeName = idx(field, _ => _.type.ofType.name)
  if (ofTypeName) {
    const fieldChildren = ofTypeName && types.find(type => field!.type!.ofType!.name! === type.name)!.fields!
      .reduce((childFields, childField) => {
        let newChildField: any
        let childrenObj: any = { ...childFields }
        if (isPrimitiveField(childField)) {
          newChildField = true
        } else if (currentDepth < depth && childField!.type!.ofType) {
          newChildField = createQueryFields(childField, types, depth, currentDepth + 1)
        }
        if (newChildField) {
          childrenObj[childField!.name!] = newChildField
        }
        return childrenObj
      }, {})
      let queryField: any = {}
      if (__args && Object.keys(__args).length) {
        queryField.__args = __args
      }
      if (fieldChildren && Object.keys(fieldChildren).length) {
        queryField = { ...queryField, ...fieldChildren }
      }
      return queryField
    }
}

// https://github.com/dupski/json-to-graphql-query
const fieldToQuery = (field: GrapphFieldValueFragment, types: Maybe<GrapphFullTypeFragment>[]): string => {
  const queryFields = createQueryFields(field, types)
  const queryJson = { query: { [field!.name!]: queryFields } }
  const queryStr = jsonToGraphQLQuery(queryJson)
  return queryStr
}

export default fieldToQuery