import { gql } from 'apollo-boost';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};


export type GrapphDirective = {
   __typename?: 'GrapphDirective',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  locations?: Maybe<Array<Maybe<Scalars['String']>>>,
  args?: Maybe<Array<Maybe<GrapphInputValue>>>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
};

export type GrapphEnumValue = {
   __typename?: 'GrapphEnumValue',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  isDeprecated?: Maybe<Scalars['Boolean']>,
  deprecationReason?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
};

export type GrapphField = {
   __typename?: 'GrapphField',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  args?: Maybe<Array<Maybe<GrapphInputValue>>>,
  type?: Maybe<GrapphType>,
  isDeprecated?: Maybe<Scalars['Boolean']>,
  deprecationReason?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
};

export type GrapphInputValue = {
   __typename?: 'GrapphInputValue',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  type?: Maybe<GrapphType>,
  defaultValue?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
};

export type GrapphSchema = {
   __typename?: 'GrapphSchema',
  id?: Maybe<Scalars['ID']>,
  queryType?: Maybe<GrapphType>,
  mutationType?: Maybe<GrapphType>,
  subscriptionType?: Maybe<GrapphType>,
  types?: Maybe<Array<Maybe<GrapphType>>>,
  directives?: Maybe<Array<Maybe<GrapphDirective>>>,
  project?: Maybe<Project>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
};

export type GrapphType = {
   __typename?: 'GrapphType',
  id?: Maybe<Scalars['ID']>,
  kind?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  fields?: Maybe<Array<Maybe<GrapphField>>>,
  inputFields?: Maybe<Array<Maybe<GrapphInputValue>>>,
  interfaces?: Maybe<Array<Maybe<GrapphType>>>,
  enumValues?: Maybe<Array<Maybe<GrapphEnumValue>>>,
  possibleTypes?: Maybe<Array<Maybe<GrapphType>>>,
  ofType?: Maybe<GrapphType>,
  createdAt?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  logIn?: Maybe<Token>,
  signUp?: Maybe<Token>,
  createProject?: Maybe<Project>,
};


export type MutationLogInArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationSignUpArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationCreateProjectArgs = {
  data: ProjectCreateInput
};

export type Project = {
   __typename?: 'Project',
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  owners?: Maybe<Array<Maybe<User>>>,
  graphQLUrl?: Maybe<Scalars['String']>,
  graphQLIntrospection?: Maybe<Scalars['String']>,
  grapphSchema?: Maybe<GrapphSchema>,
  grapphSchemaId?: Maybe<Scalars['ID']>,
};

export type ProjectCreateInput = {
  name: Scalars['String'],
  graphQLUrl: Scalars['String'],
};

export type ProjectWhereInput = {
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  graphQLIntrospection?: Maybe<Scalars['String']>,
  currentUser?: Maybe<User>,
  schema?: Maybe<GrapphSchema>,
  project?: Maybe<Project>,
  projects?: Maybe<Array<Maybe<Project>>>,
};


export type QueryGraphQlIntrospectionArgs = {
  graphQLUrl: Scalars['String']
};


export type QuerySchemaArgs = {
  where: WhereUniqueIdInput
};


export type QueryProjectArgs = {
  where: WhereUniqueIdInput
};


export type QueryProjectsArgs = {
  first?: Maybe<Scalars['Int']>,
  where?: Maybe<ProjectWhereInput>
};

export type Token = {
   __typename?: 'Token',
  jwt: Scalars['String'],
  user: User,
};

export type User = {
   __typename?: 'User',
  email: Scalars['String'],
  id: Scalars['ID'],
  projects?: Maybe<Array<Maybe<Project>>>,
  role: UserRole,
};

export enum UserRole {
  User = 'user',
  Admin = 'admin'
}

export type WhereUniqueIdInput = {
  id: Scalars['ID'],
};
export type CreateProjectMutationVariables = {
  name: Scalars['String'],
  graphQLUrl: Scalars['String']
};


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )> }
);

export type LogInMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type LogInMutation = (
  { __typename?: 'Mutation' }
  & { logIn: Maybe<(
    { __typename?: 'Token' }
    & Pick<Token, 'jwt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email' | 'id'>
    ) }
  )> }
);

export type SignUpMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp: Maybe<(
    { __typename?: 'Token' }
    & Pick<Token, 'jwt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email' | 'id'>
    ) }
  )> }
);

export type CurrentUserQueryVariables = {};


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email'>
  )> }
);

export type GrapphIntrospectionQueryVariables = {
  schemaId: Scalars['ID']
};


export type GrapphIntrospectionQuery = (
  { __typename?: 'Query' }
  & { schema: Maybe<(
    { __typename?: 'GrapphSchema' }
    & { queryType: Maybe<(
      { __typename?: 'GrapphType' }
      & Pick<GrapphType, 'name'>
    )>, mutationType: Maybe<(
      { __typename?: 'GrapphType' }
      & Pick<GrapphType, 'name'>
    )>, subscriptionType: Maybe<(
      { __typename?: 'GrapphType' }
      & Pick<GrapphType, 'name'>
    )>, types: Maybe<Array<Maybe<{ __typename?: 'GrapphType' }
      & GrapphFullTypeFragment
    >>>, directives: Maybe<Array<Maybe<{ __typename?: 'GrapphDirective' }
      & GrapphDirectiveValueFragment
    >>> }
  )> }
);

export type GrapphFullTypeFragment = (
  { __typename?: 'GrapphType' }
  & Pick<GrapphType, 'kind' | 'name' | 'description'>
  & { fields: Maybe<Array<Maybe<{ __typename?: 'GrapphField' }
    & GrapphFieldValueFragment
  >>>, inputFields: Maybe<Array<Maybe<{ __typename?: 'GrapphInputValue' }
    & GrapphInputValueFragment
  >>>, interfaces: Maybe<Array<Maybe<{ __typename?: 'GrapphType' }
    & GrapphTypeRefFragment
  >>>, enumValues: Maybe<Array<Maybe<{ __typename?: 'GrapphEnumValue' }
    & GrapphEnumValueFragment
  >>>, possibleTypes: Maybe<Array<Maybe<{ __typename?: 'GrapphType' }
    & GrapphTypeRefFragment
  >>> }
);

export type GrapphInputValueFragment = (
  { __typename?: 'GrapphInputValue' }
  & Pick<GrapphInputValue, 'name' | 'description' | 'defaultValue'>
  & { type: Maybe<{ __typename?: 'GrapphType' }
    & GrapphTypeRefFragment
  > }
);

export type GrapphEnumValueFragment = (
  { __typename?: 'GrapphEnumValue' }
  & Pick<GrapphEnumValue, 'name' | 'description' | 'isDeprecated' | 'deprecationReason'>
);

export type GrapphFieldValueFragment = (
  { __typename?: 'GrapphField' }
  & Pick<GrapphField, 'name' | 'description' | 'isDeprecated' | 'deprecationReason'>
  & { args: Maybe<Array<Maybe<{ __typename?: 'GrapphInputValue' }
    & GrapphInputValueFragment
  >>>, type: Maybe<{ __typename?: 'GrapphType' }
    & GrapphTypeRefFragment
  > }
);

export type GrapphDirectiveValueFragment = (
  { __typename?: 'GrapphDirective' }
  & Pick<GrapphDirective, 'name' | 'description' | 'locations'>
  & { args: Maybe<Array<Maybe<{ __typename?: 'GrapphInputValue' }
    & GrapphInputValueFragment
  >>> }
);

export type GrapphTypeRefFragment = (
  { __typename?: 'GrapphType' }
  & Pick<GrapphType, 'kind' | 'name'>
  & { ofType: Maybe<(
    { __typename?: 'GrapphType' }
    & Pick<GrapphType, 'kind' | 'name'>
    & { ofType: Maybe<(
      { __typename?: 'GrapphType' }
      & Pick<GrapphType, 'kind' | 'name'>
      & { ofType: Maybe<(
        { __typename?: 'GrapphType' }
        & Pick<GrapphType, 'kind' | 'name'>
        & { ofType: Maybe<(
          { __typename?: 'GrapphType' }
          & Pick<GrapphType, 'kind' | 'name'>
          & { ofType: Maybe<(
            { __typename?: 'GrapphType' }
            & Pick<GrapphType, 'kind' | 'name'>
            & { ofType: Maybe<(
              { __typename?: 'GrapphType' }
              & Pick<GrapphType, 'kind' | 'name'>
              & { ofType: Maybe<(
                { __typename?: 'GrapphType' }
                & Pick<GrapphType, 'kind' | 'name'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ProjectGrapphIntrospectionQueryVariables = {
  projectId: Scalars['ID']
};


export type ProjectGrapphIntrospectionQuery = (
  { __typename?: 'Query' }
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'graphQLUrl'>
    & { grapphSchema: Maybe<(
      { __typename?: 'GrapphSchema' }
      & Pick<GrapphSchema, 'id'>
      & { queryType: Maybe<(
        { __typename?: 'GrapphType' }
        & Pick<GrapphType, 'name' | 'kind'>
      )>, mutationType: Maybe<(
        { __typename?: 'GrapphType' }
        & Pick<GrapphType, 'name' | 'kind'>
      )>, subscriptionType: Maybe<(
        { __typename?: 'GrapphType' }
        & Pick<GrapphType, 'name' | 'kind'>
      )>, types: Maybe<Array<Maybe<{ __typename?: 'GrapphType' }
        & GrapphFullTypeFragment
      >>>, directives: Maybe<Array<Maybe<{ __typename?: 'GrapphDirective' }
        & GrapphDirectiveValueFragment
      >>> }
    )> }
  )> }
);

export type ProjectQueryVariables = {
  id: Scalars['ID']
};


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'graphQLUrl'>
  )> }
);

export type ProjectsQueryVariables = {};


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>>> }
);
export const GrapphTypeRefFragmentDoc = gql`
    fragment GrapphTypeRef on GrapphType {
  kind
  name
  ofType {
    kind
    name
    ofType {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GrapphInputValueFragmentDoc = gql`
    fragment GrapphInputValue on GrapphInputValue {
  name
  description
  type {
    ...GrapphTypeRef
  }
  defaultValue
}
    ${GrapphTypeRefFragmentDoc}`;
export const GrapphFieldValueFragmentDoc = gql`
    fragment GrapphFieldValue on GrapphField {
  name
  description
  args {
    ...GrapphInputValue
  }
  type {
    ...GrapphTypeRef
  }
  isDeprecated
  deprecationReason
}
    ${GrapphInputValueFragmentDoc}
${GrapphTypeRefFragmentDoc}`;
export const GrapphEnumValueFragmentDoc = gql`
    fragment GrapphEnumValue on GrapphEnumValue {
  name
  description
  isDeprecated
  deprecationReason
}
    `;
export const GrapphFullTypeFragmentDoc = gql`
    fragment GrapphFullType on GrapphType {
  kind
  name
  description
  fields {
    ...GrapphFieldValue
  }
  inputFields {
    ...GrapphInputValue
  }
  interfaces {
    ...GrapphTypeRef
  }
  enumValues {
    ...GrapphEnumValue
  }
  possibleTypes {
    ...GrapphTypeRef
  }
}
    ${GrapphFieldValueFragmentDoc}
${GrapphInputValueFragmentDoc}
${GrapphTypeRefFragmentDoc}
${GrapphEnumValueFragmentDoc}`;
export const GrapphDirectiveValueFragmentDoc = gql`
    fragment GrapphDirectiveValue on GrapphDirective {
  name
  description
  locations
  args {
    ...GrapphInputValue
  }
}
    ${GrapphInputValueFragmentDoc}`;
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $graphQLUrl: String!) {
  createProject(data: {name: $name, graphQLUrl: $graphQLUrl}) {
    id
    name
  }
}
    `;
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

    export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
    }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const LogInDocument = gql`
    mutation LogIn($email: String!, $password: String!) {
  logIn(email: $email, password: $password) {
    jwt
    user {
      email
      id
    }
  }
}
    `;
export type LogInMutationFn = ApolloReactCommon.MutationFunction<LogInMutation, LogInMutationVariables>;

    export function useLogInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
      return ApolloReactHooks.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, baseOptions);
    }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = ApolloReactCommon.MutationResult<LogInMutation>;
export type LogInMutationOptions = ApolloReactCommon.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!) {
  signUp(email: $email, password: $password) {
    jwt
    user {
      email
      id
    }
  }
}
    `;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;

    export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
      return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
    }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    email
  }
}
    `;

    export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
      return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
    }
      export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
      
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GrapphIntrospectionDocument = gql`
    query GrapphIntrospection($schemaId: ID!) {
  schema(where: {id: $schemaId}) {
    queryType {
      name
    }
    mutationType {
      name
    }
    subscriptionType {
      name
    }
    types {
      ...GrapphFullType
    }
    directives {
      ...GrapphDirectiveValue
    }
  }
}
    ${GrapphFullTypeFragmentDoc}
${GrapphDirectiveValueFragmentDoc}`;

    export function useGrapphIntrospectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GrapphIntrospectionQuery, GrapphIntrospectionQueryVariables>) {
      return ApolloReactHooks.useQuery<GrapphIntrospectionQuery, GrapphIntrospectionQueryVariables>(GrapphIntrospectionDocument, baseOptions);
    }
      export function useGrapphIntrospectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GrapphIntrospectionQuery, GrapphIntrospectionQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GrapphIntrospectionQuery, GrapphIntrospectionQueryVariables>(GrapphIntrospectionDocument, baseOptions);
      }
      
export type GrapphIntrospectionQueryHookResult = ReturnType<typeof useGrapphIntrospectionQuery>;
export type GrapphIntrospectionQueryResult = ApolloReactCommon.QueryResult<GrapphIntrospectionQuery, GrapphIntrospectionQueryVariables>;
export const ProjectGrapphIntrospectionDocument = gql`
    query ProjectGrapphIntrospection($projectId: ID!) {
  project(where: {id: $projectId}) {
    id
    name
    graphQLUrl
    grapphSchema {
      id
      queryType {
        name
        kind
      }
      mutationType {
        name
        kind
      }
      subscriptionType {
        name
        kind
      }
      types {
        ...GrapphFullType
      }
      directives {
        ...GrapphDirectiveValue
      }
    }
  }
}
    ${GrapphFullTypeFragmentDoc}
${GrapphDirectiveValueFragmentDoc}`;

    export function useProjectGrapphIntrospectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectGrapphIntrospectionQuery, ProjectGrapphIntrospectionQueryVariables>) {
      return ApolloReactHooks.useQuery<ProjectGrapphIntrospectionQuery, ProjectGrapphIntrospectionQueryVariables>(ProjectGrapphIntrospectionDocument, baseOptions);
    }
      export function useProjectGrapphIntrospectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectGrapphIntrospectionQuery, ProjectGrapphIntrospectionQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ProjectGrapphIntrospectionQuery, ProjectGrapphIntrospectionQueryVariables>(ProjectGrapphIntrospectionDocument, baseOptions);
      }
      
export type ProjectGrapphIntrospectionQueryHookResult = ReturnType<typeof useProjectGrapphIntrospectionQuery>;
export type ProjectGrapphIntrospectionQueryResult = ApolloReactCommon.QueryResult<ProjectGrapphIntrospectionQuery, ProjectGrapphIntrospectionQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  project(where: {id: $id}) {
    id
    name
    graphQLUrl
  }
}
    `;

    export function useProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
      return ApolloReactHooks.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
    }
      export function useProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
      }
      
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectQueryResult = ApolloReactCommon.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectsDocument = gql`
    query Projects {
  projects {
    id
    name
  }
}
    `;

    export function useProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
      return ApolloReactHooks.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
    }
      export function useProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
      }
      
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsQueryResult = ApolloReactCommon.QueryResult<ProjectsQuery, ProjectsQueryVariables>;