import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'
import { colors } from '../../../defaultStyles'
import { withRouter, RouterProps } from 'react-router'
import { GrapphRouteComponentProps } from '../index'

const styles = StyleSheet.create({
  navItemContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    flex: 1,
    paddingBottom: 15
  },
  navItemText: {
    backgroundColor: 'rgba(255, 0, 0, 0.2',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
})



export interface NavItemProps {
  name: string
  to: string
  icon?: string
}

type NavItemPropsFull = NavItemProps & GrapphRouteComponentProps & RouterProps

const NavItem: React.FC<NavItemPropsFull> = ({ name, to, history, match }) => {
  const navItemPressed = () => {
    history.push(`${match.url}${to}`)
  }
  return (
    <TouchableOpacity style={styles.navItemContainer} onPress={navItemPressed}>
      <Text style={styles.navItemText}>{name}</Text>
    </TouchableOpacity>
  )
}

export default withRouter<any>(NavItem)