import React, { useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Tile, Title, TextInput, Modal, Button } from './ui'
import { useProjectsQuery, useCreateProjectMutation, Project, ProjectsDocument } from '../apollo'
import { StyleSheet, Text, View, TouchableOpacity, TouchableOpacityProps, ActivityIndicator } from 'react-native'
import { withRouter } from 'react-router-dom'

const styles = StyleSheet.create({
  actionsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

interface AddProjectButtonProps extends TouchableOpacityProps {}

const AddProjectButton: React.FC<AddProjectButtonProps> = ({ onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Tile>+</Tile>
    </TouchableOpacity>
  );
};

interface AddProjectProps extends RouteComponentProps {}

const AddProjectComponent: React.FC<AddProjectProps> = ({ history }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [graphQLUrl, setgraphQLUrl] = useState('');
  const [createProject] = useCreateProjectMutation({
    variables: { name: projectName, graphQLUrl },
    update (cache, { data }) {
      // const { todos } = cache.readQuery({ query: GET_TODOS });
      const projectsData = cache.readQuery({ query: ProjectsDocument  })
      const projects = (projectsData as any).projects
      cache.writeQuery({
        query: ProjectsDocument,
        data: { projects: projects.concat([data!.createProject]) }
      })
    }
  })
  
  const createProjectPressed = async () => {
    try {
      const res = await createProject()
      if (res.data && res.data.createProject) {
        history.push(`/projects/${res.data.createProject.id}`)
      }
    } catch (error) {
      // TODO: We need an error state <here className=""></here>
      console.error(error)
    } finally {
      setProjectName('')
      setgraphQLUrl('')
      setModalVisible(false)
    }
  }
  return (
    <>
    <AddProjectButton onPress={() => setModalVisible(true)}/>
    <Modal visible={modalVisible} setVisible={setModalVisible}>
      <Title h2>Title</Title>
      <TextInput value={projectName} onChangeText={(text) => setProjectName(text)} label="Project Name"></TextInput>
      <TextInput value={graphQLUrl} onChangeText={(text) => setgraphQLUrl(text)} label="GraphQL Endpoint"></TextInput>
      <View style={styles.actionsContainer}>
        <View style={styles.actions}>
          <TouchableOpacity onPress={() => setModalVisible(false)}>
            <Text>Cancel</Text>
          </TouchableOpacity>
          <Button title="Create" onPress={() => createProjectPressed()} />
        </View>
      </View>
    </Modal>
    </>
  )
}

const AddProject = withRouter(AddProjectComponent)

const ProjectLinks = () => {
  const { data, loading, error } = useProjectsQuery()
  
  if (loading) {
    return <View><ActivityIndicator size="large"/></View>
  }
  if (error) {
    return <Text>{JSON.stringify(error)}</Text>
  }
  
  return (
    <>
      {(data!.projects as []).map((project: Project) => (
        <Link to={`/projects/${project.id}`} style={{ textDecoration: 'none' }} key={project.id}>
          <Tile>{project.name}</Tile>
        </Link>
      ))}
      <AddProject/>
    </>
  )
}

export default ProjectLinks