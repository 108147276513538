import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { colors } from '../defaultStyles';
import { Route, Link } from 'react-router-dom';

const styles = StyleSheet.create({
  nav: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  navItem: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  navText: {
    color: colors.primary,
    fontSize: 16,
  },
  underline: {
    paddingTop: 2,
    boxShadow: `0px 2px 0px 0px ${colors.primary}`,
  },
});

interface Props {
  to: string;
}

const NavLink: React.FC<Props> = ({ children, to }) => {
  return (
    <Route
      path={to}
      exact
      children={({ match }: { match: boolean }) => (
        <Link style={{ textDecoration: 'none' }} to={to}>
          <View style={styles.navItem}>
            <Text style={styles.navText}>{children}</Text>
            {match && <View style={styles.underline} />}
          </View>
        </Link>
      )}
    />
  );
};

export default NavLink;
